import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';

const BlockQuote = (props) => {
  return (
    <Container className="px-0">
      <Row xs={12} className="justify-content-center">
        <Col xs={12} md={9} className="m-auto">
          <div 
            className={ props.author ? 
              "bq-container" : "bq-container bq-container-no-author"
            }
          >
            <p className="bq-quote">{ props.quote }</p>
            { props.author &&
            <p className="bq-author"><span>-</span>{ props.author }</p>
            }
            { props.bottomContent &&
            <p className="bottom-content">—{ props.bottomContent }</p>
            }
          </div>
        </Col>
      </Row>
    </Container>
  )
}
export default BlockQuote;