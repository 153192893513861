import * as React from "react";
import ArticleLayout from "../articles/article-layout";
import copyList from "./copyList";
import References from "../global/references.js";

export const Content = (props) => {
  return (
    <div className={`${props.show ? "d-block" : "d-none"}`}>
      <ArticleLayout
        page={props.page}
        copyList={copyList}
        ariaLabel="A woman, Kayla, wearing a surgical mask"
        videoStillAlt="Kayla and man stand inside of a home that is being built"
        subheading="The Importance of Housing Stability for Transgender Women of Color in HIV Treatment"
        heading="Spotlight on Kayla Rena Gore, co-founder of My Sistah's House"
      />
      <References page={props.page} />
    </div>
  );
};
