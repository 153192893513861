import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import tonyStill from "../../images/Blind-angels-tony-video.png";
import zakiaStill from "../../images/Blind-angels-zakia-video.png";
import joseStill from "../../images/Blind-angels-jose-joaquin-video.png";
import dazonStill from "../../images/Blind-angels-dazon-woman-holding-hands.png";
import kaylaStill from "../../images/Blind-angels-kayla-video.png";
import eheMasterclassStill from "../../images/Deeper-dive-ehe-masterclass-video-thumbnail.png";

const getVideoStill = (page) => {
  switch (page) {
    case "tony":
      return tonyStill;
    case "champion-dafina":
      return tonyStill;
    case "zakia":
      return zakiaStill;
    case "jose":
      return joseStill;
    case "dazon":
      return dazonStill;
    case "kayla":
      return kaylaStill;
    case "ehe-masterclass":
      return eheMasterclassStill;
  }
};

const getButtonCopy = (page) => {
  switch (page) {
    case "tony":
      return "Tony's ";
    case "zakia":
      return "Zakia's ";
    case "jose":
      return "Their ";
    case "dazon":
      return "Dázon's ";
    case "kayla":
      return "Kayla's ";
    case "ehe-masterclass":
      return "Ehe masterclass's ";
  }
};

export default class VideoCTA extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Container xs={10}>
        <Row>
          <Col
            md={10}
            className="mx-auto text-center mobile-video-width"
            onClick={() => this.props.openVideo(this.props.page)}
          >
            <img
              className="mx-auto mb-md-4 w-100"
              src={getVideoStill(this.props.page)}
              alt={this.props.videoStillAlt}
            />
          </Col>
        </Row>

        {this.props.page != "ehe-masterclass" || this.props.page != "callen-lorde" && (
          <Row>
            <Col xs={7} lg={8} className="mx-auto text-center">
              <button
                onClick={() => this.props.openVideo(this.props.page)}
                className="btn rounded-pill article-cta-button"
              >
                WATCH {getButtonCopy(this.props.page)} <i>BLIND ANGELS </i>{" "}
                VIDEO
              </button>
            </Col>
          </Row>
        )}
        {this.props.page == "ehe-masterclass" && (
          <Row>
            <Col md={10} className="mx-auto video-caption mobile-caption-width">
              <p class="red-caption">
                If Not Now, When? Sustaining Urgency in Helping End the HIV
                Epidemic
              </p>
              <p>
                Moderator: Patrick Sullivan, DVM, PhD, Dipl. ACVM (Epi)
                <br />
                Speakers: Oni Blackstock, MD, MHS, and Greg Millett, MPH
              </p>
              <p
                class="play-video-link"
                onClick={() => this.props.openVideo(this.props.page)}
              >
                <strong style={{ cursor: "pointer" }}>Play video</strong>&nbsp;
                <i className="fa fa-chevron-right "></i>
              </p>
            </Col>
          </Row>
        )}
        <Row className="viral-suppression">
          <Col xs={11} lg={10} className="mx-auto text-left pt-5">
            <p>
              <span className="hang">*</span>Viral suppression means the amount
              of virus in the blood is very low or cannot be measured by a test
              (&lt;200&nbsp;c/mL). Viral suppression is the goal of HIV care and
              treatment.<sup>13</sup>
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}
