import * as React from "react";
import { Component } from 'react';
import Layout from '../../../components/global/layout'
import { Content } from '../../../components/kayla/content'


class Dazon extends Component {
  render () {
  return (
    <>
      <Layout section="articles" page="kayla">
        <Content section="articles" page="kayla" />
      </Layout>
    </>
  )
  }
}

export default Dazon;